//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import checkParams from '@/scripts/auth/checkParams.js'
import LoadingSkeleton from '@/components/LoadingSkeleton.vue'
export default {
     components: {
        CoverPhoto: () => import('@/components/CoverPhoto.vue'),
        EventScoreboard: () => import('@/components/Event/EventScoreboard.vue'),
        AchievementsList: () => import('@/components/Achievements/AchievementsList.vue'),
        EventNamestrip: () => import('@/components/Event/EventNamestrip.vue'),
        EventDashboard: () => import('@/components/Event/EventDashboard.vue'),
        EventAbout: () => import('@/components/Event/EventAbout.vue'),
        EventPublicJoin: () => import('@/components/Event/EventPublicJoin.vue'),
        EventMembers: () => import('@/components/Event/EventMembers.vue'),
        PostContainer: () => import('@/components/Posts/PostContainer.vue'),
        Gallery: () => import('@/components/Gallery/Gallery.vue'),
        InvitePrompter: () => import('@/components/InvitePrompter.vue'),
        LoadingSkeleton
    },
    props:[
        'id'
    ],
    data() {
        return {
            name: '',
            description: '',
            isLoading: false,
            groupFound:true,
            isMember: true,
            isAdmin: true,
            state: (this.$q.platform.mobile) ? 'about' : 'posts',
            privacy: 'Public',
            hasInvite: false,
            inviteData: {},
            isFullContent: false,
            isPreview: true,
            membersCount: 0,
            isOwner: false,
            metaTitle: ''
        }
    },
    computed:{
        eventData() {
            return this.$store.state.event.event
        }
    },
    metaInfo(){
        return {
            title:this.metaTitle
        }
    },
    methods: {
        fetchEvent(){
            this.$store.dispatch('event/fetchEvent',this.$route.params.id)
            .then((response) => {
                if(response.status == 'success'){
                    this.isAdmin = response.isAdmin
                    this.isMember = response.isMember
                    this.isOwner = response.isOwner
                    this.membersCount = response.membersCount
                    this.metaTitle = response.name
                }
                if(response.status == 'has_invite'){
                    this.$router.push({
                        name: 'EventInvite', 
                        params: {
                            invite: response.invite,
                            id: this.$route.params.id
                        }
                    });
                }
                if(response.status == 'event_not_found'){
                    this.$router.push('/notfound')
                }
            })
        },
        changeState(state) {
            this.state = state;
            if(state != 'posts'){
                this.isFullContent = true
            }else{
                this.isFullContent = false
            }
        },
        fullContent(){
            return {
                'isFullContent': this.isFullContent,
            }
        },
        ishidden(){
            return{
                'hidden': this.isFullContent
            }
        },
        validateID(id){
           checkParams.validateEntityParams(id)
        }
    },
    created() {
        this.validateID(this.$route.params.id)
        this.fetchEvent();
    },
    watch: {
        '$route.params.id' () {
            window.location.reload();
        },
    },

}
